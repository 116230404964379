define("discourse/plugins/Moderatori Plugin/discourse/components/custom-button", ["exports", "@ember/modifier", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template", "discourse/components/glimmer-component-with-deprecated-parent-view", "discourse/helpers/concat-class", "discourse/lib/url", "discourse-common/helpers/d-icon", "discourse-common/lib/deprecated", "discourse-i18n", "truth-helpers/helpers/or", "discourse/lib/ajax", "@ember/template-factory", "@ember/component"], function (_exports, _modifier, _object, _computed, _service, _template, _glimmerComponentWithDeprecatedParentView, _concatClass, _url, _dIcon, _deprecated, _discourseI18n, _or, _ajax, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ACTION_AS_STRING_DEPRECATION_ARGS = ["DButton no longer supports @action as a string. Please refactor to use an closure action instead.", {
    id: "discourse.d-button-action-string"
  }];
  let DButton = _exports.default = (_dec = (0, _computed.notEmpty)("args.icon"), _dec2 = (0, _computed.equal)("args.display", "link"), _dec3 = (0, _computed.empty)("computedLabel"), (_class = (_class2 = class DButton extends _glimmerComponentWithDeprecatedParentView.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "btnIcon", _descriptor2, this);
      _initializerDefineProperty(this, "btnLink", _descriptor3, this);
      _initializerDefineProperty(this, "noText", _descriptor4, this);
      if (typeof this.args.action === "string") {
        (0, _deprecated.default)(...ACTION_AS_STRING_DEPRECATION_ARGS);
      }
    }
    get forceDisabled() {
      return !!this.args.isLoading;
    }
    get isDisabled() {
      return this.forceDisabled || this.args.disabled;
    }
    get btnType() {
      if (this.args.icon) {
        return this.computedLabel ? "btn-icon-text" : "btn-icon";
      } else if (this.computedLabel) {
        return "btn-text";
      }
    }
    get computedTitle() {
      if (this.args.title) {
        return _discourseI18n.default.t(this.args.title);
      }
      return this.args.translatedTitle;
    }
    get computedLabel() {
      if (this.args.label) {
        return _discourseI18n.default.t(this.args.label);
      }
      return this.args.translatedLabel;
    }
    get computedAriaLabel() {
      if (this.args.ariaLabel) {
        return _discourseI18n.default.t(this.args.ariaLabel);
      }
      if (this.args.translatedAriaLabel) {
        return this.args.translatedAriaLabel;
      }
    }
    get computedAriaExpanded() {
      if (this.args.ariaExpanded === true) {
        return "true";
      }
      if (this.args.ariaExpanded === false) {
        return "false";
      }
    }
    keyDown(e1) {
      if (this.args.onKeyDown) {
        e1.stopPropagation();
        this.args.onKeyDown(e1);
      } else if (e1.key === "Enter") {
        this._triggerAction(e1);
      }
    }
    click(event1) {
      let topic_id1 = this.args.topic_id;
      (0, _ajax.ajax)(`/moderatori/${topic_id1}`, {}).then(uploads1 => console.log(uploads1));
    }
    mouseDown(event1) {
      if (this.args.preventFocus) {
        event1.preventDefault();
      }
    }
    _triggerAction(event1) {
      const {
        action: actionVal1,
        route: route1,
        href: href1
      } = this.args;
      if (actionVal1 || route1 || href1?.length) {
        if (actionVal1) {
          const {
            actionParam: actionParam1,
            forwardEvent: forwardEvent1
          } = this.args;
          if (typeof actionVal1 === "string") {
            (0, _deprecated.default)(...ACTION_AS_STRING_DEPRECATION_ARGS);
            if (this._target?.send) {
              this._target.send(actionVal1, actionParam1);
            } else {
              throw new Error("DButton could not find a target for the action. Use a closure action instead");
            }
          } else if (typeof actionVal1 === "object" && actionVal1.value) {
            if (forwardEvent1) {
              actionVal1.value(actionParam1, event1);
            } else {
              actionVal1.value(actionParam1);
            }
          } else if (typeof actionVal1 === "function") {
            if (forwardEvent1) {
              actionVal1(actionParam1, event1);
            } else {
              actionVal1(actionParam1);
            }
          }
        } else if (route1) {
          this.router.transitionTo(route1);
        } else if (href1?.length) {
          _url.default.routeTo(href1);
        }
        event1.preventDefault();
        event1.stopPropagation();
        return false;
      }
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{! template-lint-disable no-pointer-down-event-binding }}
      <button
        {{! For legacy compatibility. Prefer passing class as attributes. }}
        class={{concatClass
          @class
          (if @isLoading "is-loading")
          (if this.btnLink "btn-link" "btn")
          (if this.noText "no-text")
          this.btnType
        }}
        {{! For legacy compatibility. Prefer passing these as html attributes. }}
        id={{@id}}
        form={{@form}}
        aria-controls={{@ariaControls}}
        aria-expanded={{this.computedAriaExpanded}}
        tabindex={{@tabindex}}
        type={{or @type "button"}}
        ...attributes
        disabled={{this.isDisabled}}
        title={{this.computedTitle}}
        aria-label={{this.computedAriaLabel}}
        {{on "keydown" this.keyDown}}
        {{on "click" this.click}}
        {{on "mousedown" this.mouseDown}}
      >
        {{#if @isLoading}}
          {{~icon "spinner" class="loading-icon"~}}
        {{else}}
          {{#if @icon}}
            {{#if @ariaHidden}}
              <span aria-hidden="true">
                {{~icon @icon~}}
              </span>
            {{else}}
              {{~icon @icon~}}
            {{/if}}
          {{/if}}
        {{/if}}
  
        {{~#if this.computedLabel~}}
          <span class="d-button-label">
            {{~htmlSafe this.computedLabel~}}
            {{~#if @ellipsis~}}
              &hellip;
            {{~/if~}}
          </span>
        {{~else~}}
          &#8203;
          {{! Zero-width space character, so icon-only button height = regular button height }}
        {{~/if~}}
  
        {{yield}}
      </button>
    
  */
  {
    "id": "ccrnk44h",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[11,\"button\"],[16,0,[28,[32,0],[[30,1],[52,[30,2],\"is-loading\"],[52,[30,0,[\"btnLink\"]],\"btn-link\",\"btn\"],[52,[30,0,[\"noText\"]],\"no-text\"],[30,0,[\"btnType\"]]],null]],[16,1,[30,3]],[16,\"form\",[30,4]],[16,\"aria-controls\",[30,5]],[16,\"aria-expanded\",[30,0,[\"computedAriaExpanded\"]]],[16,\"tabindex\",[30,6]],[16,4,[28,[32,1],[[30,7],\"button\"],null]],[17,8],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[16,\"title\",[30,0,[\"computedTitle\"]]],[16,\"aria-label\",[30,0,[\"computedAriaLabel\"]]],[4,[32,2],[\"keydown\",[30,0,[\"keyDown\"]]],null],[4,[32,2],[\"click\",[30,0,[\"click\"]]],null],[4,[32,2],[\"mousedown\",[30,0,[\"mouseDown\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,[28,[32,3],[\"spinner\"],[[\"class\"],[\"loading-icon\"]]]]],[]],[[[41,[30,9],[[[41,[30,10],[[[1,\"            \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,[28,[32,3],[[30,9]],null]],[13],[1,\"\\n\"]],[]],[[[1,[28,[32,3],[[30,9]],null]]],[]]]],[]],null]],[]]],[41,[30,0,[\"computedLabel\"]],[[[10,1],[14,0,\"d-button-label\"],[12],[1,[28,[32,4],[[30,0,[\"computedLabel\"]]],null]],[41,[30,11],[[[1,\"…\"]],[]],null],[13]],[]],[[[1,\"​\\n\"]],[]]],[18,12,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@class\",\"@isLoading\",\"@id\",\"@form\",\"@ariaControls\",\"@tabindex\",\"@type\",\"&attrs\",\"@icon\",\"@ariaHidden\",\"@ellipsis\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/Moderatori Plugin/discourse/components/custom-button.js",
    "scope": () => [_concatClass.default, _or.default, _modifier.on, _dIcon.default, _template.htmlSafe],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "btnIcon", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "btnLink", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "noText", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "keyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mouseDown"), _class.prototype)), _class));
});